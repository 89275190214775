import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import BackgroundImage from 'gatsby-background-image'
import styled from 'styled-components'
import { device } from '../components/device'
const HeroImage = styled.div`
  margin-left: auto;
  margin-right: auto;
  max-width: 1348px;
  height: 55vh;

  color: white;

  display: flex;
  flex-flow: column;
  justify-content: center;
  margin: 25px auto;
  margin-top: 0;

  h1 {
    margin-top: 6rem;
    max-width: 75ch;
    font-weight: 700;
    color: white;
    text-shadow: 1px 1px black;
    @media (max-width: 1024px) {
      margin-top: 5rem;

      font-size: 2rem;
    }
  }

  p,
  div,
  li {
    max-width: 100ch;
    text-shadow: 1px 1px black;
    font-weight: 600;
  }
`
const HeroTekElements = styled.div`
  @media only screen and ${device.tablet} {
    margin: 25px;
  }
  @media only screen and ${device.tabletMaxL} {
    margin: 5px;
    width: 85%;
    margin-top: -4rem;
    h1 {
      font-size: 2.5em;
    }
  }

  @media (max-width: 350px) {
    font-size: 15px;
    width: 95%;
    h1 {
      font-size: 1.5em;
    }
  }
`

const SubPageHero = ({ children, title, description1, description2, component }) => {
  const data = useStaticQuery(graphql`
    query {
      hero: file(relativePath: { eq: "verksted_bakgrunn.png" }) {
        childImageSharp {
          fluid(maxWidth: 1348) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <BackgroundImage fluid={data.hero.childImageSharp.fluid}>
      <HeroImage className="subHeroTitle">
        <HeroTekElements>
          <h1>{title}</h1>
          {description1 && <p dangerouslySetInnerHTML={{ __html: description1 }} />}
          {description2 && <p dangerouslySetInnerHTML={{ __html: description2 }} />}
          {component}
          {children}
        </HeroTekElements>
      </HeroImage>
    </BackgroundImage>
  )
}

export default SubPageHero
