import React from 'react'
import SEO from '../../components/seo'
import SubPageLayout from '../../layouts/subPageLayout'
import styled from 'styled-components'
import { Layout } from '../../components/styled/layout'
import { Section } from '../../components/styled/section'
//import NextPkkHeroOption from '../components/nextPkkHeroOption'
import ChooseRegion from '../../components/small_web_comp/ChooseRegion'
const PageSection = styled(Section)`
  display: flex;
  align-items: center;
`
const Text = styled.div`
  flex: 1;
  margin-top: -3rem;
  h4 {
    border-bottom: 2px solid #dddddd;
  }
`

const MotorS = () => {
  const title = 'Motor – service'
  const description1 =
    'Hos BilXtra Verksted opererer vi utelukkende med bildeler av OE-kvalitet eller Matching Quality. Det vil si at alle våre deler er av minst like god kvalitet som originalmontert deler.'
  const description2 =
    'Lar du oss fikse bilen din, kan du derfor være trygg på at du får topp kvalitet! '

  return (
    <Layout>
      <SubPageLayout title={title} description1={description1} description2={description2}>
        <SEO title={title} />
        <PageSection>
          <Text>
            <h4>Motor – service</h4>
            <p>
              Alle verkstedene utfører km-service og motorservice etter bilprodusentenes
              spesifikasjoner. Du kan være trygg på at alle punktene følges, og at oljens kvalitet
              oppfyller bilprodusentenes krav.
            </p>
            <ChooseRegion />
          </Text>
        </PageSection>
      </SubPageLayout>
    </Layout>
  )
}

export default MotorS
