import React, { useState } from 'react'
import { graphql, navigate, useStaticQuery } from 'gatsby'
import SelectWorkshopModalMaps from '../../components/selectWorkshopModalMaps'
import { generateWorkshopPagePrefix } from '../../url-helpers'

import styled from 'styled-components'
import { Layout } from '../../components/styled/layout'
import '../../layouts/layout.css'
import Img from 'gatsby-image'

const HeroBoxHolder = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  width: 100%;
  margin-top: 20px;
  justify-content: left;
  @media (max-width: 1441px) {
    margin-top: 6px;
  }
  @media (max-width: 1025px) {
    display: grid;
    grid-template-columns: auto;
    grid-gap: 25px;
  }
  @media (max-width: 1023px) {
    margin-top: 1px;
  }
`

const GoToOrder = styled.div`
  flex: 1;
  max-width: 320px;
  min-width: 100px;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  background: var(--primary-color);
  text-align: center;

  button {
    padding: 0.6rem;
    display: block;
    color: white;
    font-weight: 400;
    text-decoration: none;
    font-size: 0.9rem;
  }
`

const Icon = styled.div`
  width: 50px;
  height: 30px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 1rem;
`
const Link = styled.div`
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center center;
  opacity: 1;
  transition: none 0s ease 0s;
  color: #fff;
  padding: 0.5rem;
  cursor: pointer;
`

export const ChooseRegion = () => {
  const [showWorkshopSelector, setShowWorkshopSelector] = useState(false)
  const data = useStaticQuery(graphql`
    query {
      clock: file(relativePath: { eq: "clock.png" }) {
        childImageSharp {
          fluid(maxWidth: 50) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <Layout>
      {showWorkshopSelector && (
        <SelectWorkshopModalMaps
          onClose={() => setShowWorkshopSelector(false)}
          onSelected={async (workshop) => {
            const prefix = generateWorkshopPagePrefix(workshop)
            await navigate(`/${prefix}/${workshop.slug}#Bestilltime`)
          }}
        />
      )}

      <HeroBoxHolder>
        <GoToOrder>
          <Link style={{ width: '500px' }} onClick={(_) => navigate('/#oversikt')}>
            <Icon>
              <Img fluid={data.clock.childImageSharp.fluid} />
            </Icon>
            Bestill time
          </Link>
        </GoToOrder>
      </HeroBoxHolder>
    </Layout>
  )
}

export default ChooseRegion
