import styled from 'styled-components'
import { device } from '../device'

export const Section = styled.section`
  max-width: 1348px;
  margin-left: auto;
  margin-right: auto;
  padding-top: 2rem;
  padding-bottom: 2rem;
  padding-left: 1rem;
  padding-right: 1rem;

  @media ${device.laptopL} {
    padding-left: 0;
    padding-right: 0;
    padding-top: 2rem;
    padding-bottom: 6rem;
  }
  @media only screen and ${device.tabletMaxL} {
    //padding-top: 8rem;
  }
`

export const SectionTitle = styled.h1`
  margin-bottom: 1rem;
  text-align: center;
  font-weight: 700;
  color: #333333;
  @media only screen and ${device.tabletMaxL} {
    font-size: 1em;
  }
`

export const SectionParagraph = styled.div`
  margin-bottom: 1rem;
  margin-left: auto;
  margin-right: auto;
  max-width: 75ch;

  @media only screen and ${device.tabletMaxL} {
    margin: 15px;
  }

  h4 {
    border-bottom: 2px solid #dddddd;
  }
`
