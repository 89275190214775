import React, { useState } from 'react'
import { graphql, navigate, useStaticQuery } from 'gatsby'
import FilterableWorkshopBox from '../components/filterableWorkshopBox'
import { MapContainer } from '../components/map/mapContainer'
import styled from 'styled-components'
import { useWorkshops } from '../hooks/use-workshops'
import { device } from './device'
import { generateWorkshopPagePrefix } from '../url-helpers'

const ModalOverlay = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 3;
  background: rgba(0, 0, 0, 0.6);
  top: 0;
  left: 0;
`

const ModalBody = styled.div`
  position: relative;
  padding: 2rem;
  top: 10vh;
  left: 50%;
  transform: translateX(-50%);

  max-width: 1000px;
  height: 70%;
  border-radius: 2px;
  background: white;
  overflow-x: hidden;

  @media ${device.tablet} {
    //max-width: 100%;
    overflow-x: hidden; //horizontal
    overflow-y: scroll; //vertical
  }
  @media ${device.tabletMaxL} {
    width: 100%;
    overflow-x: hidden; //horizontal
    overflow-y: scroll; //vertical
  }
`

const ModalHeader = styled.div`
  position: relative;
  margin-bottom: 2rem;
`

const ModalCloseButton = styled.button`
  position: absolute;
  top: 0;
  right: 0;
  font-size: inherit;
  padding: 0.5rem 1rem;
  background: transparent;
  border: 1px solid #dddddd;
`

const ModalTitle = styled.h2`
  text-align: center;
  margin-top: 0;
  font-weight: 700;
  @media ${device.tabletMaxL} {
    font-size: 1.5rem;
    text-align: left;
  }
`

const ModalContent = styled.div`
  overflow-x: scroll;
`
const Flex = styled.div`
  @media only screen and ${device.laptop} {
    display: flex;
    flex-direction: row-reverse;
  }
`
const ColumnCenter = styled.div`
  display: grid;
  grid-template-columns: 2fr 1fr;
  grid-gap: 5px;
  @media only screen and ${device.tabletMaxL} {
    grid-template-columns: 1fr;
    padding-bottom: 6rem;
  }

  @media only screen and ${device.laptop} {
    flex: 3;
    padding-bottom: 6rem;
    padding-left: 1rem;
  }
`
const Map = styled.div`
  height: 30vh;

  @media only screen and ${device.tablet} {
    height: 99%;
    width: 100%;
  }
`

const hasLatitude = (workshop) => workshop.lat
const parseFloats = (workshop) => ({
  ...workshop,
  lat: parseFloat(workshop.lat),
  lng: parseFloat(workshop.long),
})

const SelectWorkshopModalMaps = ({ onClose, onSelected }) => {
  let workshops = useWorkshops()
  const [pageHistory, setPageHistory] = useState([])
  const selectedPage = pageHistory[pageHistory.length - 1]
  const canGoBack = pageHistory.length > 0

  const {
    allCollectionPageHierarchy: { edges },
  } = useStaticQuery(graphql`
    query {
      allCollectionPageHierarchy {
        edges {
          node {
            name
            page_id
            parent_id
            level
            hidden
          }
        }
      }
    }
  `)

  const collectionPages = edges.map(({ node }) => node)

  // Dette filtrerer ut alle verksteder som ikke har lat/lon og gjør om lat/lon til floats
  const markers = workshops
    .filter(hasLatitude)
    .map(parseFloats)
    .filter((workshop) => {
      // Dersom en side er valgt så må vi filtere vekk verksteder som ikke tilhører den siden for å få kartet til
      // å vise riktig område
      if (selectedPage) {
        return workshop.collection_pages.includes(selectedPage.page_id)
      }

      // Dersom ingen side er valgt så kan vi bare vise alle verksteder
      return true
    })

  const goToWorkshop = async (workshops) => {
    const prefix = generateWorkshopPagePrefix(workshops)
    await navigate(`/${prefix}/${workshops.slug}/`)
  }

  return (
    <ModalOverlay>
      <ModalBody>
        <ModalHeader>
          <ModalTitle>Velg verksted</ModalTitle>
          <ModalCloseButton type="button" onClick={onClose}>
            Lukk
          </ModalCloseButton>
        </ModalHeader>
        <ModalContent>
          <Flex>
            <ColumnCenter>
              <FilterableWorkshopBox
                selectedPage={selectedPage}
                collectionPages={collectionPages}
                workshops={workshops}
                goToWorkshop={goToWorkshop}
                canGoBack={canGoBack}
                goBack={() => {
                  // Fjern alle sider som matcher gjeldene side. I dette tilfellet så sitter vi igjen
                  // med forrige side
                  setPageHistory(pageHistory.filter((hp) => hp.page_id !== selectedPage.page_id))
                }}
                onChange={(cp) => {
                  // Legg siden til i side historikken så vi husker forrige
                  // side i minnet
                  setPageHistory([...pageHistory, cp])
                }}
              />
              <Map>
                <MapContainer workshops={markers} zoom={4} />
              </Map>
            </ColumnCenter>
          </Flex>
        </ModalContent>
      </ModalBody>
    </ModalOverlay>
  )
}

export default SelectWorkshopModalMaps
