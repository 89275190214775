import React, { useCallback } from 'react'
import styled from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleLeft } from '@fortawesome/free-solid-svg-icons'
import { device } from './device'
import { Link } from 'gatsby'
import { generateWorkshopPagePrefixFromAffiliationName } from '../url-helpers'

const arrayMove = require('array-move')

const BluBox = styled.div`
  background: var(--primary-color);
  height: 500px;
  width: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  padding: 0;
  margin: 1px auto;
  box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.75);
`

const HedingBox = styled.div`
  text-align: center;
  flex: 6;
  border-bottom: 3px solid var(--primary-white);
  color: var(--primary-white);
  font-size: 2rem;
  font-weight: 700;
`

const ElmentHolder = styled.div`
  display: grid;
`
const ElementS = styled.div`
  margin: 5px;
  margin-left: 15px;
  margin-right: 15px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 10px;
  @media only screen and ${device.tabletMaxL} {
    grid-template-columns: 1fr;
  }
`
const ElementsButton = styled.div`
  color: white;
  border-bottom: 1px solid white;
  font-size: inherit;
  font-family: inherit;
  display: grid;
  grid-template-columns: 8fr 1fr;
  transition: background 0.25s ease-out;
  padding: 10px;
  &:hover {
    cursor: pointer;
    background: rgba(0, 0, 0, 0.05);
  }
`

const TopBoxHolder = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
  padding: 0.5rem 1rem;
`

const BackButtonStyle = styled.button`
  flex: 1;
  padding: 0.5rem 1rem;
  font-size: 1.8rem;
  border: none;
  background: transparent;

  &:active {
    background: rgba(255, 255, 255, 0.1);
  }

  &:hover {
    cursor: pointer;
  }
`

const WorkShopPageName = styled(Link)`
  color: var(--primary-white);
`

const Spacer = styled.div`
  flex: 1;
`

// Jeg har døpt om `filters` til `selectedPage` da jeg tror dette er et mer beskrivende navn
const FilterableWorkshopBox = ({
  selectedPage,
  collectionPages,
  workshops,
  goBack,
  onChange,
  goToWorkshop,
  canGoBack,
}) => {
  // Lagt til filtrering på 'hidden' her slik at eks. Oslo (fylke) ikke vises
  const parentPages = collectionPages.filter((cp) => !cp.hidden).filter((cp) => cp.level === 1)

  const array1 = arrayMove(parentPages, 10, 0)

  // Her finnes undersider av parent page dersom parent page er valgt. Hvis ikke settes den til tomt array
  const childPages = selectedPage
    ? collectionPages
        .filter((cp) => cp.level === 2)
        .filter((child) => child.parent_id === selectedPage.page_id)
    : []

  // Denne sjekker om den valgte siden har noen undersider og brukes for å vise/skjule verksted
  const selectedPageHasNoChildPages = childPages.length === 0

  // Her finnes verksteder som tilhører siden som er valgt - hvis ikke settes den til tomt array
  const workshopsByPage = selectedPage
    ? workshops.filter((w) => w.collection_pages.indexOf(selectedPage.page_id) !== -1)
    : []

  // Renderers
  const renderWorkshopPageName = useCallback(() => {
    const { name } = selectedPage
    const prefix = generateWorkshopPagePrefixFromAffiliationName(name)
    return (
      <WorkShopPageName className={WorkShopPageName} to={`/${prefix}`}>
        {name}
      </WorkShopPageName>
    )
  }, [selectedPage])

  return (
    <React.Fragment>
      <BluBox id="bluBoks">
        <TopBoxHolder>
          {canGoBack && (
            <BackButtonStyle onClick={goBack}>
              <FontAwesomeIcon icon={faAngleLeft} style={{ color: 'white' }} />
            </BackButtonStyle>
          )}
          <HedingBox>{selectedPage ? renderWorkshopPageName() : 'Velg Fylke'}</HedingBox>
          {canGoBack && <Spacer />}
        </TopBoxHolder>
        <ElmentHolder>
          <ElementS>
            {!selectedPage &&
              array1.map((cp, i) => (
                <ElementsButton key={`p-${i}`} onClick={() => onChange(cp)}>
                  {cp.name}
                </ElementsButton>
              ))}
          </ElementS>
        </ElmentHolder>

        <ElmentHolder>
          <ElementS>
            {selectedPage &&
              childPages.map((child, i) => (
                <ElementsButton key={i} onClick={() => onChange(child)}>
                  {child.name}
                </ElementsButton>
              ))}
          </ElementS>
        </ElmentHolder>

        <ElmentHolder>
          <ElementS>
            {/* Verkstedene vises kun dersom den valgte siden ikke har noen undersider */}
            {selectedPageHasNoChildPages &&
              workshopsByPage.map((child) => (
                <ElementsButton onClick={() => goToWorkshop(child)}>{child.name}</ElementsButton>
              ))}
          </ElementS>
        </ElmentHolder>
      </BluBox>
    </React.Fragment>
  )
}

export default FilterableWorkshopBox
