import { useEffect, useState } from 'react'

export const useSetFavoriteWorkshop = (workshop) => {
  useEffect(() => {
    localStorage.setItem(
      'bilxtra-web-workshop',
      JSON.stringify({ id: workshop.workshop_id, slug: workshop.slug, name: workshop.name })
    )
  }, [workshop])
}

export const useGetFavoriteWorkshop = () => {
  const [slug, setSlug] = useState('')

  useEffect(() => {
    const workshop = localStorage.getItem('bilxtra-web-workshop')
    setSlug(JSON.parse(workshop))
  }, [])

  return slug
}
